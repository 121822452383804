html {
  font-size: 100%;
}

a {
  color: black;
}

h6 {
  color: black !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  background-color: 'white';
}

button:disabled {
  opacity: 0.5;
}

.css-1jqq78o-placeholder {
  border-color: black;
  color: black;
  font-size: 14px;
}

.css-1xc3v61-indicatorContainer,
.css-1okebmr-indicatorSeparator {
  border-color: black;
  color: black;
  font-size: 14px;
}

.css-1xc3v61-indicatorContainer:hover {
  color: black
}


css-1hwfws3 .Strike__strike___1XV1b {
  display: none;
}

.uploadArea {
  border-style: dashed !important;
}

.css-1hwfws3,
.css-yk16xz-control,
.css-yk16xz-control,
.css-v28prj-control {
  min-height: 40px !important;
}

.css-1rhbuit-multiValue,
.css-128gyl7-control,
.css-yk16xz-control,
.css-18jk0ke-control,
.css-18nhwgv-multiValue,
.css-v28prj-control,
.css-39vjk4-control {
  border-color: black !important;
  border-width: thin !important;
  border-style: solid !important;
  border-radius: 3px !important;
}

.css-1fdsijx-ValueContainer,
.css-1hwfws3 {
  padding-left: 3px;
  min-height: 42px;
}

/* fix for bug 69062 - unnecessary wrapping for single item in MUI select control */
.css-1hwfws3:has(> :nth-child(-n+2):last-child),
.css-g1d714-ValueContainer:has(> :nth-child(-n+2):last-child) {
  flex-wrap: nowrap !important;
  max-height: 42px;
}

.css-2b097c-container {
  color: black !important
}

.MuiTableCell-stickyHeader {
  z-index: inherit !important;
}

.MuiTableCell-sizeSmall {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.MuiStepButton-vertical {
  padding: 0px !important;
}

.MuiStepConnector-vertical {
  padding-top: 4px !important;
  margin-left: 4px !important;
}

.MuiStepLabel-label {
  margin-left: 25px !important;
}

pre {
  white-space: pre-wrap;
  margin: 0px;
}

table.ibnrCopyPaste {
  /*  overflow-y: 'scroll';*/
  background-color: white;
  width: 100%;
  /*  table-layout:fixed;*/
}

table.inbrCopyPaste tr td.cell {
  overflow: hidden;
  padding: 10px;
  border: solid 1px;
  text-align: center;
}

.data-grid-container .data-grid .cell .data-editor {
  width: calc(100%) !important;
  height: 40px;
}

.MuiAccordionSummary-content {
  margin: 0 !important;
}